<script>
import { mapStores } from 'pinia'

import { useAccountStore } from '@/stores'

export default {
  name: 'FreqAskedQuestions',

  props: {},

  methods: {
    submit(form) {
      if (!this.ok) return

      this.isLoading = true
      this.account
        .submitQuestion(form)
        .then((response) => {
          console.log(response)
        })
        .finally(() => (this.isLoading = false))
    },
  },

  watch: {},

  computed: {
    ...mapStores(useAccountStore),

    ok() {
      return Boolean(this.form?.message && this.form?.message?.length > 10)
    },
  },

  data: () => ({
    isDebug: import.meta.env.VITE_DEBUG == 'true',
    isLoading: false,

    form: {
      message: null,
    },
  }),

  mounted() {},
}
</script>

<template>
  <div class="content account-view pt-4">
    <nav class="mb-6" aria-label="">
      <o-button @click="$router.go(-1)" icon-left="arrow-left"> </o-button>
    </nav>

    <section>
      <h3>Frequently Asked Questions</h3>

      <p>
        Welcome to the FAQ section for Liff App Protect Tags! Here are some of
        our most frequently asked questions:
      </p>
      <p>
        <strong>What is a Protect Tag?</strong> A Protect Tag is a secure
        scannable tag that contains contact and entity information. To access
        the information, you can either scan the QR code located on the back of
        the tag or type in the unique URL link associated with the QR code.
      </p>
      <p>
        <strong>How do Protect Tags work?</strong> Protect Tags utilize QR codes
        and unique URL links to efficiently, securely, and easily share your
        contact information and information related to the registered entity. By
        scanning the QR code or accessing the URL, users can quickly retrieve
        the relevant details to aid in reuniting the found item.
      </p>
      <p>
        <strong> What is the Liff App? </strong>The Liff App was specifically
        designed to enhance the management of lost and found processes for
        organizations and events. With over 90 events annually across the US and
        internationally utilizing the app for Lost and Found services, the
        effectiveness of Protect Tags is further amplified within this Lost and
        Found network.
      </p>
      <p>
        <strong>Is there an app for Protect Tags and the Liff App?</strong> No,
        not in the traditional app store sense. Our service operates as a
        Webapp, which means you don't need to download anything. It's accessible
        from any internet-connected device.
      </p>
      <p>
        <strong>
          My Protect Tag broke/I lost my Protect Tag. Can I get a replacement?
        </strong>
        Absolutely! We provide one free replacement without any questions asked.
        To request a replacement, please send an email to
        support@liffhappens.com with your order number (located in the email
        receipt you received when you placed your order) and inform us about
        your need for a replacement.
      </p>
      <p>
        <strong>I tried logging in, but I'm not receiving an email.</strong>
        Make sure you are spelling your email correctly. If you haven't received
        your login email, it's possible that it got filtered into your email's
        spam or promotions folder. Kindly check these folders first. If you
        still can't locate the email, fill out the form below, and our team will
        assist you promptly!
      </p>
      <p>
        <strong>
          I'm not receiving email notifications when my tag is scanned.
        </strong>
        Our emails might be ending up in your spam or promotions folder. To
        resolve this, locate the emails in these folders and mark them as "not
        spam" or "not promotion."
      </p>
      <p>
        <strong>
          How do I log in to view/edit my account without scanning the tag?
        </strong>
        You can access and update your account information anytime by visiting
        our website at
        <a class="has-text-info" href="https://accounts.liff.app/"
          >accounts.liff.app</a
        >.
      </p>
      <p>
        <strong>
          Is this also a tracker? If nobody scans the tag, can I still track
          where my pet is?
        </strong>
        No, Protect Tags are not active trackers. They work when someone finds
        your items and scans the tag, allowing them to choose whether to share
        their current GPS location with you. However, our Pet Tags support an
        Apple Airtag, which can provide real-time tracking, sound alerts,
        separation notifications, and so much more. Pair the integrated tracking
        of Apple with the ease of QR Code contact management of Protect Tags.
      </p>
      <p>
        <strong>
          If I want to get multiple Protect Tags for my stuff, can I have one QR
          code for easier updates?
        </strong>
        Each Protect Tag must have a unique QR code. However, one account can
        support multiple Protect Tags, making it easy to manage and keep track
        of all your belongings.
      </p>

      <template v-if="false">
        <h3>Still have questsions?</h3>

        <form @submit.prevent="submit(form)">
          <o-field label="Message">
            <o-input v-model="form.message" type="textarea" required></o-input>
          </o-field>
          <o-button
            :loading="isLoading"
            :disabled="!ok || isLoading"
            variant="primary"
            @click="submit(form)">
            Send
          </o-button>
        </form>
      </template>
    </section>
  </div>
</template>

<style lang="scss" scoped></style>
