<script>
import { mapStores, mapWritableState } from 'pinia'

import { useAccountStore } from '@/stores'
import { useOptionsStore } from '@/stores'
// import liffapi from '@/liffapi.js'
// import utils from '@/utils.js'

export default {
  name: 'LogoutView',

  components: {},

  props: {},

  methods: {},

  computed: {
    ...mapWritableState(useOptionsStore, ['opts']),
    ...mapStores(useAccountStore),
  },

  data: () => ({
    isLoading: false,
  }),

  mounted() {
    this.account.clearAuth()
  },
}
</script>

<template>
  <div class="is-blue has-text-centered">
    <h1 class="is-size-1 has-text-white mb-6">
      You've been <span class="is-yellow">logged out!</span>
    </h1>

    <router-link to="/login" class="has-text-white"> Login </router-link>
  </div>
</template>

<style lang="scss">
.is-blue {
  background-color: #007bff;
  width: 100vw;
  height: 100vh;
  padding: 2rem;
}

.is-yellow {
  color: #f3ff58;
}
</style>
