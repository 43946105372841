<script>
import { mapStores } from 'pinia'
import ProtectTagAKidDetails from '@/components/ProtectTagAKidDetails.vue'
import LostModeWidget from '@/components/LostModeWidget.vue'

import { useAccountStore } from '@/stores'
// import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

function asForm(report) {
  return (({ key, imei, tagName, isLostMode, lostMessage, preferences }) => ({
    key,
    imei,
    tagName,
    isLostMode,
    lostMessage,
    preferences,
  }))(report)
}

export default {
  name: 'ProtectDetails',

  components: {
    ProtectTagAKidDetails,
    LostModeWidget,
  },

  props: {},

  data: () => ({
    isDebug: import.meta.env.VITE_DEBUG == 'true',
    isLoading: false,

    original: null,
    form: {
      key: null,
      imei: null,
      tagName: null,
      isLostMode: false,
      lostMessage: null,

      preferences: {
        show_contact_info: false,
        show_child_details: false,
      },
    },
  }),

  computed: {
    ...mapStores(useAccountStore),

    artUrl() {
      return this.report?.artUrl || this.report?.details?.image_url || null
    },

    isOldTag() {
      return ['bag_tag', 'sticker', 'key_tag'].includes(this.report?.model)
    },

    isWallpaper() {
      return this.report?.model == 'wallpaper'
    },

    isTagAKid() {
      return this.report?.model == 'kid_tag'
    },

    report() {
      let key = this.$route.params.key
      return this.account.protectDetails.registrations.find((p) => p.key == key)
    },

    isFormDiff() {
      let imeiDiff = this.form?.imei != this.original?.imei
      let ret = [
        this.form.isLostMode != this.original.isLostMode,
        this.form.lostMessage != this.original.lostMessage,
        this.form.tagName != this.original.tagName,
        imeiDiff,
        !utils.isEqual(this.form?.details, this.original.details),
        !utils.isEqual(this.form?.preferences, this.original.preferences),
      ]
      return ret.some((i) => i)
    },

    ok() {
      return Boolean(this.isFormDiff && this.form?.key)
    },
  },

  async mounted() {
    if (!this.report) {
      this.isLoading = true
      await this.account.getProtectDetails()
      this.isLoading = false
    }

    if (this.report) {
      let form = asForm(this.report)
      this.form = Object.assign({}, form)
      this.original = utils.duplicate(form)
    }
  },

  methods: {
    submit(form) {
      if (!this.ok || !this.isChanged(form)) return

      this.isLoading = true
      this.account
        .updateProtect(form)
        .then((response) => {
          utils.toast(response.message, 'success')
          this.original = asForm(response.entity)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    isChanged(form) {
      return this.original !== null && !utils.isEqual(form, this.original)
    },

    wallpaperDownloadUrl(report) {
      let domain = import.meta.env.VITE_ROLLBAR_ENV
      let url = `${location.protocol}//${report.organization}.${domain}/#/protect/${report.key}/download/`
      return url
    },
  },
}
</script>

<template>
  <div v-if="report" class="content account-view pt-4">
    <nav class="mb-6 mx-2" aria-label="">
      <o-button icon-left="arrow-left" @click="$router.go(-1)" />
    </nav>

    <ProtectTagAKidDetails v-if="isTagAKid" :report="report" />

    <section v-if="isWallpaper">
      <a
        :href="wallpaperDownloadUrl(report)"
        target="_blank"
        class="has-text-info px-2">
        Get wallpaper
      </a>
    </section>

    <section v-if="isWallpaper || isOldTag">
      <figure class="has-text-centered">
        <img v-if="artUrl" :src="artUrl" style="max-height: 4rem" />
        <o-icon v-else size="large" icon="wallpaper" />

        <p>{{ report.encodedId }}</p>
      </figure>

      <LostModeWidget
        v-model="form.isLostMode"
        v-model:message="form.lostMessage"
        @blur="submit(form)" />

      <section class="mb-4 mx-3">
        <p>Privacy Settings</p>

        <div v-if="form.preferences" class="prefs-col">
          <o-switch
            v-model="form.preferences.show_contact_info"
            size="small"
            @update:model-value="submit(form)">
            <o-icon icon="phone" class="offset-icon" />
            <span class="is-size-6">Show Contact Information</span>
          </o-switch>
        </div>
      </section>

      <div class="has-text-centered m-4">
        <o-button
          tag="a"
          :href="report.shortUrl"
          target="_blank"
          icon-right="open-in-new"
          class="has-text-info"
          variant="text">
          Preview
        </o-button>
      </div>

      <div class="is-flex">
        <div class="column has-text-left" style="flex-grow: 2">
          <o-field v-if="isWallpaper" label="IMEI">
            <o-input v-model="form.imei" expanded @blur="submit(form)" />
          </o-field>

          <template v-else>
            <o-field label="Tag Name">
              <o-input v-model="form.tagName" expanded @blur="submit(form)" />
              <template #message>
                <p>Identitfy the item you're tagging</p>
              </template>
            </o-field>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.protect-content {
  margin: 0 1rem 0 1rem;
}

.report {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.friend {
  display: flex;
  justify-content: space-between;
}

.account-view {
  min-height: 20rem;
  max-width: 40rem;
  margin: auto;
}
</style>
