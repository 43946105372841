<script>
import { mapStores } from 'pinia'
import { useOruga } from '@oruga-ui/oruga-next'

import { useAccountStore } from '@/stores'
import FriendEdit from '@/accounts/modals/FriendEdit.vue'
import FriendSummary from '@/components/FriendSummary.vue'
import ProtectSummary from '@/components/ProtectSummary.vue'
import ScanTag from '@/modals/ScanTag.vue'
// import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

const oruga = useOruga()

export default {
  name: 'AccountProtect',

  components: { FriendSummary, ProtectSummary },

  props: {},

  data: () => ({
    isDebug: import.meta.env.VITE_DEBUG == 'true',
    isLoading: false,

    modal: null,

    friendForm: {
      name: null,
      email: null,
    },
  }),

  computed: {
    ...mapStores(useAccountStore),

    registrationsView() {
      let registrations = [...this.account.protectDetails.registrations]

      return registrations.sort((a, b) => utils.dateSort(a, b, true))
    },
  },

  watch: {},

  mounted() {
    this.isLoading = true
    this.account
      .getProtectDetails()
      .catch((err) => {
        console.debug(err)
        if (err.status == 401) {
          this.$router.push('/login')
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    isVerified(friend) {
      return friend?.verfied || false
    },

    deleteFriend(n) {
      this.account.removeFriend(n)
      utils.toast('Friend removed', 'success')
    },

    addFriend() {
      let friend = {
        name: null,
        email: null,
      }

      let modal = oruga.modal.open({
        component: FriendEdit,
        props: {
          loading: this.isLoading,
          title: 'Add a friend',
          friend: friend,
        },
        events: {
          save: (val) => {
            this.isLoading = true
            this.account
              .addFriend(val)
              .then((response) => {
                console.log(response)
                modal.close()
              })
              .finally(() => (this.isLoading = false))
          },
        },
        trapFocus: true,
      })
    },

    openFriendEdit(friend, n) {
      this.modal = oruga.modal.open({
        component: FriendEdit,
        props: {
          loading: this.isLoading,
          title: 'Edit friend',
          friend: friend,
          index: n,
        },
        events: {
          save: (val) => {
            console.log(n, val)
            this.isLoading = true
            this.account
              .updateFriend(n, val)
              .then(() => {
                this.modal.close()
                utils.toast('Friend Updated', 'success')
              })
              .finally(() => (this.isLoading = false))
          },
        },
        trapFocus: true,
      })
    },

    onScan(url) {
      console.log(url)

      this.account
        .registerProtect(url)
        .then((response) => {
          console.log(response)
          this.$router.push({
            name: 'account-protect-details',
            params: { key: response.entity.key },
          })
        })
        .catch((err) => {
          console.debug(err)
          utils.toast(err?.data?.message, 'danger')
          if (err.status == 403) {
            // location = code
          }
        })
        .finally(() => (this.isLoading = false))
    },

    openScanTag() {
      let modal = this.openModal({
        parent: this.$el,
        component: ScanTag,
        events: {
          scan: (url) => this.onScan(url).then(() => modal.ctx.close()),
        },
        props: {
          scroll: 'keep',
        },
      })
    },
  },
}
</script>

<template>
  <div class="content account-view">
    <nav class="breadcrumb has-dot-separator" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <router-link to="/" class="" aria-current="page">
            Protect
          </router-link>
        </li>
      </ul>
    </nav>

    <section>
      <h3>Registered Items</h3>
      <p class="is-size-7">Protect Wallpaper and Tags you've registered.</p>

      <div class="mb-4">
        <template v-if="account.hasReports">
          <router-link
            v-for="report in registrationsView"
            :key="report.key"
            :to="`/protect/${report.key}`"
            class="report box p-2"
            style="min-height: 5rem">
            <ProtectSummary :report="report" />
          </router-link>
        </template>

        <p v-else>None</p>
      </div>

      <div v-if="true" class="is-flex" style="justify-content: flex-end">
        <o-button class="landing-btn" variant="primary" @click="openScanTag">
          + Tag
        </o-button>
      </div>
    </section>

    <section>
      <h3 class="friends-header">
        Friends <i class="is-size-6">({{ account.friendCount }} / 3)</i>
      </h3>
      <p class="is-size-7">They get notified when your items gets found.</p>

      <div class="friends mb-4">
        <template v-if="account.hasFriends">
          <template
            v-for="(friend, n) in account.protectDetails.friends"
            :key="`friend-${n}`">
            <FriendSummary
              :friend="friend"
              @edit="(val) => openFriendEdit(val, n)"
              @delete="deleteFriend(n)" />
          </template>
        </template>

        <p v-else>None</p>
      </div>

      <div class="is-flex" style="justify-content: flex-end">
        <o-button
          :disabled="account.friendCount >= 3"
          class="landing-btn"
          variant="primary"
          @click="addFriend"
          >+ Friend</o-button
        >
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.protect-content {
  margin: 0 1rem 0 1rem;
}

.report {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.friend {
  display: flex;
  justify-content: space-between;
}
.account-view {
  min-height: 20rem;
  max-width: 40rem;
  margin: auto;
}
</style>
