<script>
import { mapStores } from 'pinia'
import { nextTick } from 'vue'

import utils from '@/utils.js'
import LostModeWidget from '@/components/LostModeWidget.vue'
import { useAccountStore } from '@/stores'

function asTaKForm(report) {
  return (({ key, isLostMode, lostMessage, preferences, details }) => ({
    key,
    isLostMode,
    lostMessage,
    preferences,
    details,
  }))(report)
}

export default {
  name: 'ProtectTagAKidDetails',

  components: { LostModeWidget },

  watch: {
    form: {
      handler(val) {
        this.isFormDiff = Boolean(
          val?.isLostMode != this.original.isLostMode ||
            val?.lostMessage != this.original.lostMessage ||
            !utils.isEqual(val?.details, this.original.details) ||
            !utils.isEqual(val?.preferences, this.original.preferences)
        )
      },
      deep: true,
    },
  },

  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapStores(useAccountStore),

    consumerQRCodeUrl() {
      let host = import.meta.env.VITE_ROLLBAR_ENV
      if (host.includes('ngrok')) {
        host = `dash.${host}`
      }
      let userKey = this.account.user.key
      return `https://${host}/qrcodes/prereg?consumer=${userKey}`
    },

    isPreregistration() {
      return this.report?.isPreregistration
    },

    encodedId() {
      return this.report?.encodedId
    },

    isDogTag() {
      return this?.report?.model == 'dog_tag'
    },

    isTagAKid() {
      return this?.report?.model == 'kid_tag'
    },

    hasShowChildInfo() {
      return this.report?.preferences?.show_child_details
    },

    hasShowContactInfo() {
      return this.report?.preferences?.show_contact_info
    },

    ok() {
      return Boolean(this.isFormDiff && this.form?.key)
    },
  },

  methods: {
    tagName(tag) {
      return tag?.details?.name ?? ''
    },

    isChanged(form) {
      return this.original !== null && !utils.isEqual(form, this.original)
    },

    async submit(form) {
      await nextTick()
      if (!this.ok || !this.isChanged(form)) {
        console.log(`not changed ${this.ok} | ${this.isChanged(form)}`)
        return
      }

      this.showPicker = false
      this.isLoading = true
      this.account
        .updateProtect(form)
        .then((response) => {
          utils.toast(response.message, 'success')
          this.original = asTaKForm(response.entity)
          this.isFormDiff = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    artUrl(report) {
      return (
        report?.details?.image_url ||
        'https://bulma.io/images/placeholders/128x128.png'
      )
    },
  },

  data: () => ({
    isLoading: false,
    isFormDiff: false,

    original: null,
    form: {
      key: null,
      details: {},
      preferences: {},

      isLostMode: false,
      lostMessage: null,
    },
  }),

  mounted() {
    let form = asTaKForm(this.report)
    this.form = Object.assign(this.form, form)
    this.original = utils.duplicate(form)
  },
}
</script>

<template v-if="report">
  <div class="protect-details content" style="">
    <section v-if="isPreregistration" class="has-text-centered">
      <h3>Preregistration</h3>
      <p>
        You'll need to find the Guest Services booth and show your activation
        code to a guest services staffer.
      </p>

      <p class="mb-0">
        {{ tagName(report) }}
      </p>

      <img :src="consumerQRCodeUrl" />
    </section>

    <template v-else>
      <LostModeWidget
        @blur="submit(form)"
        v-model="form.isLostMode"
        v-model:message="form.lostMessage" />

      <section class="mb-4 mx-3">
        <p>Privacy Settings</p>

        <div class="prefs-col">
          <o-switch
            v-model="form.preferences.show_child_details"
            @update:modelValue="submit(form)"
            size="small">
            <o-icon icon="teddy-bear" class="offset-icon" />
            <span class="is-size-6">Show Child Information</span>
          </o-switch>

          <o-switch
            v-model="form.preferences.show_contact_info"
            @update:modelValue="submit(form)"
            size="small">
            <o-icon icon="phone" class="offset-icon" />
            <span class="is-size-6">Show Contact Information</span>
          </o-switch>
        </div>
      </section>

      <div class="has-text-centered m-4">
        <o-button
          tag="a"
          :href="report.shortUrl"
          target="_blank"
          icon-right="open-in-new"
          class="has-text-info"
          variant="text">
          Preview
        </o-button>
      </div>

      <section>
        <h3>Child Information</h3>

        <div class="is-flex is-justify-content-center">
          <div class="has-text-centered">
            <figure class="image is-128x128 my-2">
              <img class="is-rounded" :src="artUrl(report)" style="" />
            </figure>
            <p>ID: {{ encodedId }}</p>
          </div>
        </div>

        <o-field label="Name" message="(optional)">
          <o-input
            v-model="form.details.name"
            data-test="form-tagName"
            @blur="submit(form)">
          </o-input>
        </o-field>

        <o-field label="Description" message="(optional)">
          <o-input
            v-model="form.details.description"
            data-test="form-tagDescription"
            @blur="submit(form)">
          </o-input>
        </o-field>

        <o-field label="Age" message="(optional)">
          <o-input
            v-model="form.details.age"
            data-test="form-tagAge"
            @blur="submit(form)">
          </o-input>
        </o-field>
      </section>

      <section v-if="report" class="is-position-relative contact-info">
        <h3>Contact Information</h3>
        <o-button
          class="edit-icon has-text-info"
          icon-right="pencil"
          tag="router-link"
          to="/profile"
          variant="text" />

        <o-field>
          <p>{{ report.name }}</p>
        </o-field>
        <o-field>
          <p>{{ report.email }}</p>
        </o-field>
        <o-field>
          <p>{{ report.phone }}</p>
        </o-field>
      </section>
    </template>
  </div>
</template>

<style scoped lang="scss">
.protect-summary {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  min-height: 6rem;
}

.offset-icon {
  vertical-align: middle;
}

.prefs-col {
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

img {
  object-fit: cover;
  width: 8rem;
  height: 8rem;
}

.contact-info {
  position: relative;
  color: #024457;
  z-index: 1001;
}

.contact-info .edit-icon {
  position: absolute;
  right: 0;
  border-radius: 50%;
}

.contact-info .edit-icon:before {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 0px;
  left: 0px;
}

.contact-info .edit-icon:after {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
</style>
