<script>
import { mapStores } from 'pinia'
import { useOrgStore } from '@/stores'
// import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

export default {
  name: 'FriendEdit',

  components: {},

  props: {
    title: {
      type: String,
      default: 'Edit your Friend',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    friend: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  emits: ['save'],

  data: () => ({
    form: {
      name: null,
      email: null,
    },
    errors: {
      name: null,
      email: null,
    },
  }),

  computed: {
    ...mapStores(useOrgStore),

    ok() {
      return true
    },
  },

  async mounted() {
    this.form = Object.assign({}, this.friend)
    console.log('edit', this.friend, Boolean(this.friend))
  },

  methods: {
    submit() {
      // something
    },

    validateForm(form) {
      console.log(form)
      let errs = {}

      if (!utils.isValidName(form.name)) {
        errs.name = 'Invalid name'
      }

      this.errors = Object.assign(this.errors, errs)
    },

    classFromError(err) {
      return err ? 'danger' : ''
    },
  },
}
</script>

<template>
  <div class="container box">
    <p>{{ title }}</p>

    <o-field label="Name">
      <o-input
        v-model="form.name"
        :custom-class="isNameErrorClass"
        expanded
        placeholder="Name">
      </o-input>
    </o-field>

    <o-field label="Email">
      <o-input
        v-model="form.email"
        :custom-class="isEmailErrorClass"
        expanded
        type="email"
        placeholder="Email">
      </o-input>
    </o-field>

    <nav class="is-flex is-justify-content-flex-end">
      <o-button
        :class="{ 'is-loading': loading }"
        variant="primary"
        type="is-text"
        @click="$emit('save', form)">
        Save
      </o-button>
    </nav>
  </div>
</template>

<style scoped lang="scss">
.modal {
  gap: 1rem;
  justify-content: center;
  background-color: white;
}
</style>
